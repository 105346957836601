<template>
  <div class="mpstyle">
    <div class="box1 between-center">
      <van-icon name="arrow-left" size="20" @click="$router.go(-1)" />
      <div class="b1t1">{{$t('text.t341')}}</div>
      <div class="b1t2" @click="getinfo('reloading')">{{$t('text.t342')}}</div>
    </div>
    <div class="box2">
      <div class="item between-center">
        <div>{{$t('text.t343')}}</div>
        <div>{{info.whole_total}} PE</div>
      </div>
      <div class="item between-center">
        <div>{{$t('text.t104')}}</div>
        <div class="end-center">
          <div>{{info.contract?.slice(0,7)}}...{{info.contract?.slice(info.contract?.length-5)}}</div>
          <img
            src="@/assets/img/img83.png"
            alt=""
            class="item-copy"
            @click="copy(info.contract)"
          />
        </div>
      </div>
      <div class="item between-center">
        <div>{{$t('text.t344')}}</div>
        <div>{{info.cumulative_output}} OMT</div>
      </div>
      <div class="item between-center">
        <div>{{$t('text.t345')}}</div>
        <div>{{info.cumulative_destruction}} OMT</div>
      </div>
      <div class="item between-center">
        <div>{{$t('text.t346')}}</div>
        <div>{{info.day_omt}} OMT</div>
      </div>
      <div class="item between-center">
        <div>{{$t('text.t347')}}</div>
        <div>{{info.remaining_pool}} OMT</div>
      </div>
    </div>
    <!-- <div class="box3">
      <div class="t1">True NFT质押挖矿规则</div>
      <div class="t2">
        1、True NFT质押挖矿规则，True NFT质押挖矿规则True NFT质押挖矿规则，True
        NFT质押挖矿规则True NFT质押挖矿规则，True NFT质押。 2、挖矿规则True
        NFT质押挖矿规则，True NFT质押挖矿规则True NFT质押挖矿规则，True
        NFT质押挖矿规则True NFT质押挖矿规则，True NFT质押挖。 3、矿规则True
        NFT质押挖矿规则，True NFT质押挖矿规则True NFT质押挖矿规则。 4、True
        NFT质押挖矿规则True NFT质押挖矿规则，True NFT质押挖矿规则True
        NFT质押挖矿规则，True NFT质押挖矿规则
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      info:{}
    };
  },
  created(){
    this.getinfo()
  },
  methods:{
    getinfo(type = 'loading'){
      this.$http.get('/core/poolList').then(res=>{
        this.info = res.data
        if(type == 'reloading'){
          this.$toast(this.$t('text.t430'))
        }
      })
    }
  }
};
</script>

<style lang="less">
.mpstyle {
  .box1 {
    padding: 13px 20px;
    border-bottom: 1px solid #e1e1e1;
    .b1t1 {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
      flex: 1;
      padding: 0 20px;
    }
    .b1t2 {
      font-size: 12px;
      font-weight: bold;
      color: #0b46cb;
    }
  }
  .box2 {
    .item {
      padding: 25px 20px;
      border-bottom: 1px solid #e1e1e1;
      font-weight: bold;
      color: #323232;
      .item-copy {
        width: 13px;
        height: 13px;
        margin-left: 10px;
      }
    }
  }
  .box3 {
    padding: 24px 20px;
    font-weight: 500;
    color: #909090;
    font-size: 12px;
    .t1 {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
}
</style>